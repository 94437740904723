<template>
  <div class="profile">
    <div class="wlv">
      <i class="rvt icon">account_circle</i>
      <div class="cjz">Profile</div>
      <progress-element class="sxo" :active="fetching" />
    </div>
    <div class="vhs">
      <input-element
        type="text"
        label="First Name"
        v-model="inputs.firstName"
        :error="inputErrors.firstName ? 'Field is required' : ''"
        @blur="validateInput('firstName')"
      />
      <input-element
        class="margin-top"
        type="text"
        label="Last Name"
        v-model="inputs.lastName"
        :error="inputErrors.lastName ? 'Field is required' : ''"
        @blur="validateInput('lastName')"
      />
    </div>
    <div class="vhs">
      <mobile-input-component
        label="Mobile Number"
        v-model="inputs.mobileNumber"
        :error="inputErrors.mobileNumber ? 'Invalid number' : ''"
        @input="validateInput('mobileNumber')"
      />
      <input-element
        class="margin-top"
        disabled
        type="email"
        label="Working Email Address"
        :model-value="user.email"
      />
    </div>
    <div class="vhs">
      <input-element
        type="text"
        label="Position"
        v-model="inputs.work.position"
        :error="inputErrors.work.position ? 'Field is required' : ''"
        @blur="validateInput('position')"
      />
      <BaseInputWithSuggestion
        class="margin-top"
        v-model="inputs.company"
        :options="companiesOptions"
        label="Company"
        :error="inputErrors.company ? 'Field is required' : ''"
        @update:model-value="updateCompanyValue"
        @blur="validateInput('company')"
      />
      <select-element
        class="margin-top"
        :model-value="inputs.industry.id"
        :error="
          inputs.industry.id !== '*' && inputErrors.industry
            ? 'Field is required'
            : ''
        "
        :options="industriesOptions.concat({ value: '*', text: 'Other' })"
        label="Industry"
        @blur="validateInput('industry')"
        @update:model-value="updateIndustryValue"
      />
      <input-element
        v-show="inputs.industry.id === '*'"
        v-model="inputs.industry.value"
        :error="inputErrors.industry ? 'Field is required' : ''"
        class="margin-top"
        label="Please specify your industry name"
        @blur="validateInput('industry')"
      />
    </div>
    <div class="juq">
      <router-link :to="{ name: 'test' }">
        <button-element class="fwb" outlined>
          Take the Assessment
        </button-element>
      </router-link>
      <div class="qfc">
        <button-element
          class="fwb"
          :disabled="fetching || updating"
          @click="updateProfile"
        >
          Save
          <progress-element class="sxo" :active="updating" />
        </button-element>
      </div>
    </div>
    <flash-message-component ref="errorDialog" type="error">
      Oops! Something went wrong. Please try again later.
    </flash-message-component>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import CompanyInput from "@/components/company-input/company-input.vue";
import FlashMessage from "@/components/flash-message/flash-message.vue";
import MobileInput from "@/components/mobile-input/mobile-input.vue";
import { initialFuse } from "@/helpers/fuse";
import store from "@/store";
import { FETCH_PROFILE, GET_USER, UPDATE_PROFILE } from "@/store/action-types";
import useInputs from "./use-inputs";
import {
  getIndustryList,
  getCompanyList,
} from "@/services/firebase/masterdata.js";
import BaseInputWithSuggestion from "@/components/BaseInputWithSuggestion.vue";

export default {
  components: {
    "company-input-component": CompanyInput,
    "flash-message-component": FlashMessage,
    "mobile-input-component": MobileInput,
    BaseInputWithSuggestion,
  },

  async beforeRouteEnter(to, from, next) {
    let user = await store.dispatch(GET_USER);
    if (!user) {
      next({ name: "login" });
      return;
    }
    next();
  },

  setup() {
    let store = useStore();

    let user = computed(() => store.state.user.user);
    let profile = computed(() => store.state.user.profile);
    let fetching = ref(false);
    let companyList = ref([]);

    let companiesOptions = computed(() => {
      const options = {
        threshold: 0.6,
        useExtendedSearch: true,
        keys: ["text"],
      };
      const companies = initialFuse(companyList.value, options);
      const results = companies
        .search(`^${inputs.value.company.value}`)
        .map(({ item }) => item);
      return results;
    });

    let industriesOptions = ref([]);
    let updating = ref(false);
    let errorDialog = ref(null);

    let {
      inputs,
      inputErrors,
      populateInputs,
      validateInput,
      validateInputs,
    } = useInputs();

    let fetchProfile = async () => {
      try {
        fetching.value = true;
        await store.dispatch(FETCH_PROFILE);
        populateInputs(profile.value);
      } catch {
        errorDialog.value.show();
      } finally {
        fetching.value = false;
      }
    };

    const fetchIndustriesList = async () => {
      const result = await getIndustryList();
      industriesOptions.value = result.map(({ id, name }) => {
        return {
          value: id,
          text: name,
        };
      });
    };

    const fetchCompaniesList = async () => {
      const companiesSnap = await getCompanyList();

      const companies = companiesSnap.map(({ id, name }) => {
        return {
          value: id,
          text: name,
        };
      });

      companyList.value = companies;
    };

    let updateProfile = async () => {
      let profile = validateInputs();
      if (!profile) return;
      try {
        updating.value = true;
        await store.dispatch(UPDATE_PROFILE, { profile });
      } catch {
        errorDialog.value.show();
      } finally {
        updating.value = false;
      }
    };

    const updateCompanyValue = (value) => {
      inputs.value.company = value;
    };
    const updateIndustryValue = (value) => {
      inputs.value.industry = {
        id: value,
      };
    };
    onMounted(() => {
      fetchProfile();
      fetchIndustriesList();
      fetchCompaniesList();
    });

    return {
      user,
      fetching,
      updating,
      errorDialog,
      inputs,
      inputErrors,
      validateInput,
      updateProfile,
      companiesOptions,
      updateCompanyValue,
      industriesOptions,
      updateIndustryValue,
    };
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use '@/styles/variables';

.wlv {
  display: flex;
  align-items: center;
}

.rvt {
  line-height: map.get(variables.$font-sizes, "lg");
}

.cjz {
  margin-left: 16px;
  font-weight: map.get(variables.$font-weights, "bold");
  font-size: map.get(variables.$font-sizes, "lg");
}

.sxo {
  margin-left: 16px;
}

.vhs {
  margin-top: 24px;
  padding: 24px;
  background-color: white;
  border-radius: 4px;
}

.margin-top {
  margin-top: 24px;
}

.juq {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.fwb {
  justify-content: center;
  width: 100%;
}

.qfc {
  margin-top: 8px;
}

@media (min-width: map.get(variables.$breakpoints, "sm")) {
  .juq {
    flex-direction: row;
    justify-content: space-between;
  }

  .qfc {
    margin-top: 0;
  }
}

@media (min-width: map.get(variables.$breakpoints, "lg")) {
  .vhs {
    margin-top: 32px;
    padding: 32px;
  }

  .margin-top {
    margin-top: 32px;
  }

  .juq {
    margin-top: 32px;
  }
}
</style>
